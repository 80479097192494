import React, { useState, useMemo } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Select from "react-select";
import Masonry from "react-masonry-css";
import Tilt from "react-tilt";
import { uniq } from "lodash-es";

import Layout from "../components/layout/Layout";
import QuoteRow from "../components/QuoteRow";
import ArticleItem from "../components/ArticleItem";
import SalesQuestion from "../components/SalesQuestion";

import green_bg from "../assets/images/green_bg.jpg";

export default ({ data, location }) => {
    const seoData = { image: green_bg };

    const getSelectOption = (value) => {
        return { label: value, value };
    }

    const availableCategories = uniq(['All'].concat(data.contentfulReportsAndWhitepapers.reportsAndWhitepapers.map((r) => r.category)))
        .map((category) => getSelectOption(category));

    const [selectedCategory, setSelectedCategory] = useState(getSelectOption('All'));

    const shownReportItems = useMemo(() => {
        if (selectedCategory.value !== 'All') {
            return data.contentfulReportsAndWhitepapers.reportsAndWhitepapers.filter((r) => r.category === selectedCategory.value);
        }
        return data.contentfulReportsAndWhitepapers.reportsAndWhitepapers;
    }, [selectedCategory]);

    return (
        <Layout
            title="Reports & Whitepapers"
            pageContentId="reports-and-whitepapers-content"
            transparentNavigation={true}
            seoData={seoData}
            location={location}
        >
            <main
                id="reports-and-whitepapers-content"
                style={{ marginTop: "-80px" }}
            >
                <section className="hero is-fullheight">
                    <GatsbyImage
                        image={getImage(data.bg)}
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0"
                        }}
                        alt=""
                    />
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="is-flex is-flex-direction-column ">
                                <span
                                    className="size-100 font-variable is-white margin-bottom-0"
                                    style={{ lineHeight: '1.4' }}
                                >
                                    Insights
                                </span>
                                <span
                                    className="font-bold size-100 is-transparent text-stroke-white margin-bottom-0"
                                    style={{ lineHeight: '1' }}
                                >
                                    &
                                </span>
                                <span
                                    className="font-bold size-100 is-transparent text-stroke-white margin-bottom-0"
                                    style={{ lineHeight: '1' }}
                                >
                                    Analyst Reports
                                </span>
                            </h1>

                            <div className="columns">
                                <div className="column margin-top-50 is-half is-offset-one-quarter">
                                    <p className="is-white font-regular size-20 margin-bottom-10">
                                        {data.contentfulReportsAndWhitepapers.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-grey-1">
                    <div className="container">
                        <div className="container margin-bottom-30">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <h2 className="size-40 has-text-centered ingress margin-bottom-30">
                                        {data.contentfulReportsAndWhitepapers.reportAndWhitepapersTitle}
                                    </h2>
                                    <div className="columns is-multiline is-mobile">
                                        <div className="column is-12-mobile">
                                            <h3 className="size-30 has-text-centered ingress">
                                                select category:
                                            </h3>
                                        </div>
                                        <div className="column is-12-mobile">
                                            <Select
                                                defaultValue={selectedCategory}
                                                onChange={setSelectedCategory}
                                                options={availableCategories}
                                                aria-label="select report and whitepaper category"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Masonry
                            breakpointCols={{ default: 3, 1200: 2, 800: 1 }}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column"
                        >
                            {shownReportItems.map((reportItem, i) => (
                                <Tilt className="Tilt" options={{ max: 5, scale: 1.015 }} key={i}>
                                    <article className="Tilt-inner">
                                        <Link to={`/reports-and-whitepapers/${reportItem.slug}`}>
                                            <div className="card is-white-bg is-card-shadow">
                                                <div className="card-content">
                                                    <div className="media">
                                                        <div className="media-content">
                                                            <h4 className="size-50 is-white text-stroke-black margin-bottom-40">
                                                                {reportItem.type}
                                                            </h4>
                                                            <h3 className="title size-30 is-orange">
                                                                {reportItem.title}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h4
                                                            className="size-20 margin-top-10 margin-bottom-20"
                                                            style={{ fontFamily: 'Gilroy-Regular'}}
                                                        >
                                                            <strong>{`Category: ${reportItem.category}`}</strong>
                                                        </h4>
                                                        <p className="size-18 line-height-26 margin-bottom-30">
                                                            {reportItem.description}
                                                        </p>
                                                        <span className="is-orange is-orange-border-bottom read-more">
                                                            read more
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </article>
                                </Tilt>
                            ))}
                        </Masonry>
                    </div>
                </section>

                <section
                    className="section padding-y-80"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <QuoteRow id={data.contentfulReportsAndWhitepapers.quoteWidgetId} />
                </section>

                {data.contentfulReportsAndWhitepapers.relatedArticles.length && (
                    <section className="hero">
                        <div className="hero-body">
                            <div className="container">
                                <h2 className="ingress size-40 is-black">
                                    {data.contentfulReportsAndWhitepapers.relatedArticlesTitle}
                                </h2>
                                <div className="columns is-mobile is-multiline">
                                    {data.contentfulReportsAndWhitepapers.relatedArticles.map((post, i) => (
                                        <div className="column is-4-desktop is-12-mobile" key={i}>
                                            <ArticleItem
                                                item={post}
                                                color={post.color || ["green"]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <SalesQuestion
                    color="orange"
                    email="juha@frends.com"
                    name="Juha Moisio"
                    tel="+358 40 739 1412"
                    title="Global Partners"
                    isGrey={true}
                />
            </main>
        </Layout>
    );
}

export const query = graphql`query ReportsAndWhitepapersPageQuery {
    contentfulReportsAndWhitepapers (
        id: {eq: "5b46c312-7652-5733-8894-37286e8882e6"}
      ) {
        quoteWidgetId
        description
        reportAndWhitepapersTitle
        reportsAndWhitepapers {
            category
            type
            title
            description
            slug
        }
        relatedArticlesTitle
        relatedArticles {
            title
            articleUrl
            color
            subtitle
            date(formatString: "DD.MM.YYYY")
            category
            liftImage {
                gatsbyImageData(width: 1820, quality: 100)
                title
            }
        }
      }
    bg: file(relativePath: {eq: "images/green_bg.jpg"}) {
        childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
    }
}
`